"use strict";
export default function controlVh() {
  calculateVh();
  window.visualViewport.addEventListener("resize", calculateVh);
}
function calculateVh() {
  const vh = Math.floor(window.visualViewport.height) * 0.01;
  const offsetTop = window.visualViewport.offsetTop;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  document.documentElement.style.setProperty("--offset-top", `${offsetTop}px`);
}
